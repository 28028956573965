<!-- 商品入库记录列表 -->
<template>
	<div 
	 v-loading.fullscreen="uploadloading"
	 :element-loading-text="$t('tipsInfo.uploading')"
	 element-loading-spinner="el-icon-loading"
	 element-loading-background="rgba(0, 0, 0, 0.8)"
	 class="app-container">
	  <div class="listHeader">
	  	<i></i><span>{{$t('ksaWhInscanLogs.pageTitle')}}</span>
	  </div>
	  <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
	    style="margin:10px 0 0 20px">
	    <!-- <el-form-item label prop="productCode">
	      <el-input type="textarea" v-model="productCode" placeholder="请输入客户商品编码" clearable />
	    </el-form-item> -->
	    <el-form-item>
	      <el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
	  		<el-button icon="el-icon-refresh" @click="handleRefresh">{{$t('commonInfo.refreshBtn')}}</el-button>
	    </el-form-item>
	  </el-form>
	  
	  <!-- 展示 -->
	  <el-table style="margin:0 15px;width: calc(100% - 30px)" v-loading="loading" :data="dataList" border>
	    <el-table-column :label="$t('commonInfo.orderNoColumn')" align="center" prop="orderNo"/>
	    <el-table-column :label="$t('commonInfo.clientProductCode')" align="center" prop="clientSkuNo" />
	    <el-table-column :label="$t('commonInfo.productName')" align="center" prop="productNm" />
	  	<el-table-column :label="$t('commonInfo.count')" align="center" prop="count" />
	  	<el-table-column :label="$t('commonInfo.inventoryTime')" align="center" prop="opsTime">
				<template slot-scope="{ row }">
				  <div>{{ dateFormats(row.opsTime )}}</div>
				</template>
			</el-table-column>
	  </el-table>
	  <div class="pageBottom">
	    <el-pagination
	      @size-change="handleSizeChange"
	      @current-change="handleCurrentChange"
	      :current-page="queryParams.page"
	      :page-sizes="[10, 20, 30, 40]"
	      :page-size="queryParams.limit"
	      layout="total, sizes, prev, pager, next, jumper"
	      :total="total"
	    ></el-pagination>
	  </div>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
			uploadloading:false,
      queryParams: {
				page:1,
				limit:10,
				// productCode:'',
				clientId:'',
      },
			prefixAwbNo:'',//客户五位数代码
			companyProductCode:'',// 批量检索
			productCode:'',//批量检索
			loading:false,
			dataList:[],
			total:0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
	},
  //方法集合
  methods: {
		// 分页条数
		handleSizeChange(size) {
		  this.queryParams.limit = size;
		  this.queryParams.page = 1;
		  this.getList();
		},
		// 分页页数
		handleCurrentChange(page) {
		  this.queryParams.page = page;
		  this.getList();
		},
		// 搜索商品入库记录列表
		async getList() {
			this.loading = true;
			this.dataList = [];
			this.queryParams.clientId = this.prefixAwbNo;
			const res = await this.$http.get("/stockLog/list/1",{
				params:this.queryParams
			});//入库标志为1 出库标志为-1
			
			if (res.code === 200) {
			  this.loading = false;
			  this.dataList = res.data.list;
				this.total = res.data.totalCount;
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		
		handleQuery(){
			this.queryParams.page = 1;
			this.getList();
			
		},
		handleRefresh(){
			this.queryParams = {
				page:1,
				limit:10,
			}
			this.getList();
		},
		dateFormats: function(time) {
		  var date = new Date(time);
		  var year = date.getFullYear();
		  /* 在日期格式中，月份是从0开始的，因此要加0
		   * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
		   * */
		  var month =
		    date.getMonth() + 1 < 10
		      ? "0" + (date.getMonth() + 1)
		      : date.getMonth() + 1;
		  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
		  var hours =
		    date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
		  var minutes =
		    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
		  var seconds =
		    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
		  // 拼接
		  return (
		    year +
		    "-" +
		    month +
		    "-" +
		    day +
		    " " +
		    hours +
		    ":" +
		    minutes +
		    ":" +
		    seconds
		  );
		}
  },
  created() {
		let customInfo = JSON.parse(window.sessionStorage.getItem("customInfo"));
		this.prefixAwbNo = customInfo.prefixAwbNo;
		this.getList();
	},
  mounted() {}
};
</script>
<style scoped>
	.app-container {
		padding: 10px;
	}
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px;
	}
</style>
